
export const sessionStoreKeys = {
    start_url:'oidc_start_url',
    jwks: 'oidc_jwks',
    well_known: 'oidc_well_known',
    verifier: 'oidc_verifier',
    state:'oidc_state',
    access_token:'oidc_access_token',
    id_token:'oidc_id_token',
    refresh_token:'oidc_refresh_token',
    expires_at:'oidc_expires_at',
    customs_tenant: 'customs-tenant'
  }
  
  export interface OidcConfig{
    authority:string;
    post_logout_redirect_uri?:string;
    redirect_uri:string;
    client_id:string;
    audience:string;
    scope:string;
  }
  
  export interface OidcData{
    config?:OidcConfig | null;
    jwks?:any;
    well_known?: any;
    access_token?: string | null;
    id_token?: string | null;
    refresh_token?: string| null;
    expires_at?: Date | null;
  }
  
  const oidcData:OidcData  = {
    config:undefined,
    jwks: null,
    well_known: null,
    access_token: undefined,
    id_token: undefined,
    refresh_token: undefined,
    expires_at: undefined
  };
  
  
  const oidcHelper = {   
    cleanCompleteState: () => {
      sessionStorage.removeItem(sessionStoreKeys.well_known);
      sessionStorage.removeItem(sessionStoreKeys.jwks);
      oidcHelper.cleanSession();
    },
    cleanSession: () => {
      sessionStorage.removeItem(sessionStoreKeys.id_token);
      sessionStorage.removeItem(sessionStoreKeys.access_token);
      sessionStorage.removeItem(sessionStoreKeys.state);
      sessionStorage.removeItem(sessionStoreKeys.verifier);
      sessionStorage.removeItem(sessionStoreKeys.refresh_token);
      sessionStorage.removeItem(sessionStoreKeys.expires_at);
      sessionStorage.removeItem(sessionStoreKeys.start_url);
      sessionStorage.removeItem(sessionStoreKeys.start_url);
      sessionStorage.removeItem(sessionStoreKeys.customs_tenant);
    },
    setTokenResponse: (tokenResponse:any) => {
      sessionStorage.setItem(sessionStoreKeys.id_token, tokenResponse.id_token);
      sessionStorage.setItem(sessionStoreKeys.access_token, tokenResponse.access_token);
      sessionStorage.setItem(sessionStoreKeys.refresh_token, tokenResponse.refresh_token);
      sessionStorage.setItem(sessionStoreKeys.expires_at, (Date.now()+ tokenResponse.expires_in * 1000).toString()); 
    },
    readTokenResponse: () => {
      oidcData.id_token = sessionStorage.getItem(sessionStoreKeys.id_token);
      oidcData.access_token = sessionStorage.getItem(sessionStoreKeys.access_token);
      oidcData.expires_at = new Date(Number.parseInt(sessionStorage.getItem(sessionStoreKeys.expires_at)  || "0"));
      oidcData.refresh_token = sessionStorage.getItem(sessionStoreKeys.refresh_token);
    },
    base64URLEncode: (str:string) => {
      const base64 = btoa(str);
      return base64
          .replace(/\+/g, '-')
          .replace(/\//g, '_')
          .replace(/=/g, '');
    },
    toHashString:(byteArray: number[]) => {
      const result = String.fromCharCode.apply(null, byteArray);
      return result;
    },
    getParamString: (params:any):string => {
      let result = params;
      if (result && typeof result === 'object') {
        result = Object.keys(result).map(function (key) {
          return encodeURIComponent(key) + '=' + encodeURIComponent(result[key]);
        }).join('&');
      }
      return result;
    },
    makeRequest:  (opts:any) => {
      return new Promise(function (resolve, reject) {
        var xhr = new XMLHttpRequest();
        xhr.open(opts.method, opts.url);
        xhr.responseType = 'json';
        if(opts.responseType){
          xhr.responseType = opts.responseType;
        }
    
        xhr.onload = function () {
          if (this.status >= 200 && this.status < 300) {
            resolve(xhr.response);
          } else {
            reject({
              status: this.status,
              statusText: xhr.statusText
            });
          }
        };
        xhr.onerror = function () {
          reject({
            status: this.status,
            statusText: xhr.statusText
          });
        };
        if (opts.headers) {
          Object.keys(opts.headers).forEach(function (key) {
            xhr.setRequestHeader(key, opts.headers[key]);
          });
        }
        var params = oidcHelper.getParamString(opts.params); 
        xhr.send(params);
      });
    },  
    generateRandom: (len:number)=> {  
      const unreserved =
        'ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789-._~';
      var arr = new Uint8Array((len || 40) / 2)
      window.crypto.getRandomValues(arr);
      arr = arr.map(x => unreserved.charCodeAt(x % unreserved.length));
      const result = String.fromCharCode.apply(null, arr as unknown as number[]);
      return oidcHelper.base64URLEncode(result);
    },
    calcEncryptedHash: async (message: string) => {
      const msgUint8 = new TextEncoder().encode(message);                           // encode as (utf-8) Uint8Array
      const hashBuffer = await window.crypto.subtle.digest('SHA-256', msgUint8);           // hash the message
      const hashArray = Array.from(new Uint8Array(hashBuffer));                     // convert buffer to byte array
      const hashHex = oidcHelper.toHashString(hashArray);                                      // convert bytes to hex string
      return oidcHelper.base64URLEncode(hashHex);
    },
    startCodeFlow: async () => {
      var verifier =oidcHelper.generateRandom(80);
      var state =  oidcHelper.generateRandom(8);
      sessionStorage.setItem(sessionStoreKeys.verifier, verifier);
      sessionStorage.setItem(sessionStoreKeys.state, state);
      sessionStorage.setItem(sessionStoreKeys.start_url, window.location.href);
      var challenge =  await oidcHelper.calcEncryptedHash(verifier); 
      const redirectUrl = `${oidcData.well_known.authorization_endpoint}?response_type=code&code_challenge=${challenge}&code_challenge_method=S256&client_id=${oidcData.config?.client_id}&redirect_uri=${oidcData.config?.redirect_uri}&scope=${oidcData.config?.scope}&audience=${oidcData?.config?.audience}&state=${state}`;
      window.location.replace(redirectUrl);
  
    },
    postCode: async (code: string) => {
      const verifier = sessionStorage.getItem(sessionStoreKeys.verifier);
      const tokenResponse = await oidcHelper.makeRequest({
        method: 'POST',
        url: oidcData.well_known.token_endpoint,
        params: {
          grant_type: 'authorization_code',
          client_id: oidcData.config?.client_id,
          code_verifier: verifier, 
          code: code,
          redirect_uri: oidcData.config?.redirect_uri,
        },
        headers: {
          "Content-Type": "application/x-www-form-urlencoded"
        }
      }) as any;
      oidcHelper.setTokenResponse(tokenResponse);
      const startUrl =  sessionStorage.getItem(sessionStoreKeys.start_url);
      if(startUrl){
        sessionStorage.removeItem(sessionStoreKeys.start_url);
        window.location.replace(startUrl);
      }
      else {
        window.location.replace(oidcData.config?.redirect_uri ||"/");
      }
  
    },
    continueCodeFlow: async () => {
      oidcHelper.readTokenResponse();
      const urlParams = new URLSearchParams(window.location.search);
      const code = urlParams.get('code');
      // when using hybrid flow:
      // const urlParams = new URLSearchParams(window.location.hash.replace('#',''));
      // const code = urlParams.get('code');
      const stateParam = urlParams.get('state');
      if(!!oidcData.access_token){
        if(oidcData.expires_at && oidcData.expires_at > new Date()){
          return;
        }
        oidcHelper.cleanSession();
      }
      if(!!code) {    
        await oidcHelper.postCode(code);
        return;
      }
      await oidcHelper.startCodeFlow();
      return;
    }
  }
  
  
    export const oidcClient = {
      makeGetHttpRequest: async (url:string) => {
        var getResponse = await oidcHelper.makeRequest({
            method: 'GET',
            url: url
          });
          return getResponse;
      },
      login: async (options:OidcConfig) => {
        oidcData.config = options;
        const well_known_json = sessionStorage.getItem(sessionStoreKeys.well_known);
        const jwks_json = sessionStorage.getItem(sessionStoreKeys.jwks);
      
        if(!well_known_json || !jwks_json){
          var wellKnownPromise = oidcHelper.makeRequest({
            method: 'GET',
            url: `${oidcData.config.authority}.well-known/openid-configuration`
          });
          var jwksPromise = oidcHelper.makeRequest({
            method: 'GET',
            url: `${oidcData.config.authority}.well-known/openid-configuration/jwks`
          });
          const data = await Promise.all([wellKnownPromise, jwksPromise])
          sessionStorage.setItem(sessionStoreKeys.well_known,  JSON.stringify(data[0])) ;
          sessionStorage.setItem(sessionStoreKeys.jwks, JSON.stringify(data[1]));
          oidcData.well_known = data[0];
          oidcData.jwks = data[1];
        } else {
          oidcData.well_known = JSON.parse(well_known_json);
          oidcData.jwks =JSON.parse(jwks_json);
        }
        await oidcHelper.continueCodeFlow();
         return { ...oidcData };;
      },
      relogin: async ()  => {  
        oidcHelper.cleanCompleteState();
        oidcHelper.startCodeFlow(); 
         return { ...oidcData };;
      },
      logout: async() => {
        oidcHelper.cleanSession();
        const params = {
          id_token_hint: oidcData.id_token || oidcData.access_token,
          post_logout_redirect_uri: oidcData.config?.post_logout_redirect_uri || oidcData.config?.redirect_uri
        };
        const paramString = oidcHelper.getParamString(params);
        const redirectUrl = `${oidcData.well_known.end_session_endpoint}?${paramString}`;
        window.location.replace(redirectUrl);
         return { ...oidcData };; 
      },
      refreshToken: async() => {
        if(!oidcData.refresh_token){
          throw "No Refresh Token";
        }
        const tokenResponse = await oidcHelper.makeRequest({
          method: 'POST',
          url: oidcData.well_known.token_endpoint,
          params: {
            grant_type: 'refresh_token',
            client_id: oidcData.config?.client_id,
            refresh_token: oidcData.refresh_token
          },
          headers: {
            "Content-Type": "application/x-www-form-urlencoded"
          }
        }) as any; 
        oidcHelper.setTokenResponse(tokenResponse);
        oidcHelper.readTokenResponse();
         return { ...oidcData };;
      },
      getCurrentLogin: () => {
         return { ...oidcData };;
      }
    }; 
