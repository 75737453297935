import { oidcClient, OidcConfig } from './oidcClient';
const tempStore = {
  featues:null,
  options: null
}

fetch('assets/settings.json')
  .catch(err => console.error('Error loading options', err))
  .then( async (optionsResult) => {
    if(!optionsResult){
      throw "Error while Options";
    }
    const  options = await optionsResult.json();

    tempStore.options = options;
    return oidcClient.login(options as OidcConfig)
  })
  .catch(err => console.error('Error loading to authenticate', err))
  .then(() => import('./bootstrap'))
  .catch(err => console.error(err));
